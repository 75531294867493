import React from "react"
import Header from "../components/Header"

const ThankYouPage = () => (
  <React.Fragment>
    <Header title="Thank You" subtitle="Thanks for contacting us" />
  </React.Fragment>
)

export default ThankYouPage
